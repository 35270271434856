// make router for all pages

// import react
import React from 'react';

// import css
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

// import router
import { BrowserRouter, Routes, Route } from "react-router-dom";

// import pages
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import PartnersAndClients from './pages/PartnersAndClients';
import Projects from './pages/Projects';
import Contact from './pages/Contact';
import NotFound from './pages/NotFound';





const Router = () => {
    
    return (
        <BrowserRouter>
            <Routes>
                <Route exact path="/" element={<Home />} />
                <Route exact path="/about" element={<About />} />
                <Route exact path="/services" element={<Services />} />
                <Route exact path="/partners-and-clients" element={<PartnersAndClients />} />
                <Route exact path="/projects" element={<Projects />} />
                <Route exact path="/contact" element={<Contact />} />
                <Route path="*" element={<NotFound />} />
            </Routes>

        </BrowserRouter>
    );
}

export default Router;